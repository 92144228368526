import { TransformType } from '@mr-yum/cdn-image'
import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { AddIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'urql'

import { Image } from '@/components/Shared/Image'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  AddToCartDocument,
  GetPersonalisedUserItemsDocument,
} from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'
import { getOrderingSlugFromType, routes } from '@/lib/routes'

export const PersonalisedItems = () => {
  const { venueSlug } = useVenueContext()
  const { logEventLegacy } = useLoggerLegacy()
  const { orderingType } = useOrderingTypeContext()

  const [, addToCart] = useMutation(AddToCartDocument)

  const [
    { data: personalisedItemsData, fetching: fetchingPersonalisedItemsData },
  ] = useQuery({
    query: GetPersonalisedUserItemsDocument,
    pause: isServer || !venueSlug,
    variables: {
      venueSlug,
    },
    requestPolicy: 'cache-first',
  })

  const personalisedItems = useMemo(
    () =>
      personalisedItemsData?.getPersonalisedUserItems.filter(
        (item) => item.isAvailable,
      ) || [],
    [personalisedItemsData],
  )

  const handleOnClick = useCallback(
    async (itemId: string) => {
      if (!personalisedItems) {
        return
      }

      const { data, error } = await addToCart({
        input: {
          venueSlug,
          orderingType,
          item: {
            id: itemId,
            quantity: 1,
          },
        },
      })

      if (!error) {
        logEventLegacy('Personalised item successfully added', {
          item: data,
        })

        void navigate(routes.cart, {
          venueSlug,
          orderingTypeSlug: getOrderingSlugFromType(orderingType),
        })
      }
    },
    [personalisedItems, addToCart, venueSlug, orderingType, logEventLegacy],
  )

  if (fetchingPersonalisedItemsData || personalisedItems?.length === 0) {
    return null
  }

  return (
    <div className="flex w-full flex-col px-4 py-3 pb-4 md:w-auto md:pr-8 lg:pr-12">
      <span className="my-label-md">Picked for you &#x2728;</span>
      <div
        className={cn('mt-4', {
          'w-full': personalisedItems.length === 1,
          'flex gap-4 overflow-x-auto whitespace-nowrap scrollbar-hide':
            personalisedItems.length > 1,
        })}
      >
        {personalisedItems.map((item) => (
          <a
            onClick={() => handleOnClick(item.id)}
            key={item.name}
            className="flex min-h-[84px] items-center rounded border bg-interactive-subtle-surface hover:bg-interactive-subtle-surface-hover"
          >
            <div className="background-image-item relative h-[84px] w-[84px] flex-shrink-0 rounded-l bg-surface-subtle bg-cover">
              {item.cdnImage && (
                <Image
                  image={item.cdnImage}
                  transform={TransformType.SQUARE}
                  sizes="143px"
                  layout="fill"
                  objectFit="cover"
                  className="rounded-l"
                />
              )}
            </div>
            <div className="space-between flex flex-grow gap-4 p-2">
              <div className="flex-grow">
                <p className="my-body-md-bold">{item.name}</p>
                <p className="">{item.priceData.displayPrice}</p>
              </div>

              <div className="flex flex-shrink-0 items-center">
                <AddIcon />
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}
