import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useSheetToggle } from '@/components/VenueV2/utils/sheetUtils'
import {
  getOrderingTypeName,
  OrderingTypeItem,
} from '@/components/VenueV2/utils/venueUtils'
import { useOrderingTypeContext } from '@/contexts/VenueOrderContext'
import { OrderingType, VenueLandingQuery } from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'

interface Props {
  venue: VenueLandingQuery['guestVenue']
  items: OrderingTypeItem[]
}

export const OrderingTypeButton = ({ venue, items }: Props) => {
  const { logEventLegacy } = useLoggerLegacy()
  const sheetToggle = useSheetToggle()
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    logEventLegacy('Clicked on order type selector')
    void sheetToggle({
      sheet: 'ordering-type',
    })
  }, [logEventLegacy, sheetToggle])

  const { orderingType } = useOrderingTypeContext()
  const name = getOrderingTypeName(venue, orderingType, intl)

  const hasMultipleOrderingTypes = items.length > 1

  if (!hasMultipleOrderingTypes && orderingType === OrderingType.DineIn) {
    return null
  }

  if (!hasMultipleOrderingTypes) {
    return (
      <span
        className="block rounded-md border bg-action-secondary-surface px-4 py-2 text-action-secondary-foreground my-label-sm"
        data-testid="change-ordering-type"
      >
        <span className="max-w-[85vw] truncate">{name}</span>
      </span>
    )
  }

  return (
    <Button
      type="button"
      variant="link-primary"
      size="sm"
      onClick={handleOnClick}
      className="min-h-5 items-start gap-0"
      aria-label={intl.formatMessage({
        defaultMessage: 'Change ordering type',
        id: 'nCRaPf',
      })}
      data-testid="change-ordering-type"
      rightIcon={
        <ChevronDownIcon width={20} height={20} className="flex-shrink-0" />
      }
    >
      <span className="line-clamp-6 max-w-[166px] text-left">{name}</span>
    </Button>
  )
}
