import Router, { useRouter } from 'next/router'
import React, { createContext, ReactNode, useCallback, useState } from 'react'
import { CombinedError, useMutation } from 'urql'

import { useEonxEnvContext } from '@/components/EonX/EonxEnv'
import { useVenueContext } from '@/contexts/VenueOrderContext'
import {
  EonxLoginWithPartnerDocument,
  Partner,
  ResetCartDocument,
} from '@/gql/graphql'
import { getCleanPath } from '@/lib/utils'

import { useEffectOnce } from './useEffectOnce'
import { useLoggerLegacy } from './useLogger'

type PartnerLoginResult = {
  partnerResolved: boolean
  partnerError?: CombinedError
}

export const PartnerLoginContext = createContext<PartnerLoginResult>({
  partnerResolved: false,
})

export const WithPartnerLogin = ({
  children,
  ...props
}: {
  children: ReactNode
} & PartnerLoginProps) => {
  const partnerLoginResult = usePartnerLogin(props)

  return (
    <PartnerLoginContext.Provider value={partnerLoginResult}>
      {children}
    </PartnerLoginContext.Provider>
  )
}

export type PartnerLoginProps = {
  partner: Partner
}

export const usePartnerLogin = ({ partner }: PartnerLoginProps) => {
  const { query } = useRouter()
  const [resolved, setResolved] = useState(query.eonxCheckInId ? false : true)
  const { logEventLegacy, identifyApp } = useLoggerLegacy()
  const { enableEonx, disableEonx } = useEonxEnvContext()
  const { venueSlug } = useVenueContext()
  const router = useRouter()
  const [{ error: eonxLoginWithPartnerError }, eonxLoginWithPartner] =
    useMutation(EonxLoginWithPartnerDocument)
  const [, resetCart] = useMutation(ResetCartDocument)

  const onEonxSuccess = useCallback(() => {
    enableEonx()
    identifyApp(Partner.Eonx)
    setResolved(true)
  }, [identifyApp, enableEonx])

  /*
    Reset cookies during a check-in error
  */
  const onEonxError = useCallback(() => {
    disableEonx()
    identifyApp(Partner.Unspecified)
    setResolved(true)
  }, [disableEonx, identifyApp])

  // remove eonxCheckInId from URL after login
  const replaceCleanPath = useCallback(async () => {
    await Router.replace(
      getCleanPath(router.pathname, {
        sheet: router.query?.sheet,
      }),
      router.asPath
        ? getCleanPath(router.asPath, {
            sheet: router.query?.sheet,
          })
        : undefined,
      { shallow: true },
    )
  }, [router])

  const eonxCheckIn = useCallback(
    async ({ eonxCheckInId }: { eonxCheckInId: string }) => {
      const { error } = await eonxLoginWithPartner({
        authToken: eonxCheckInId,
      })

      if (!error) {
        await resetCart({ partner: Partner.Eonx })
        onEonxSuccess()
        logEventLegacy(`Eonx check in success`, { venueSlug })
        // Only remove the check-in ID from URL on success
        // On error we still want it in the URL so if the user refreshes the page it will error again.
        // We don't really want the user to recover from this without another valid check-in ID.
        void replaceCleanPath()
      } else {
        onEonxError()
        logEventLegacy(`Eonx check in failure`, { venueSlug })
      }
    },
    [
      onEonxSuccess,
      onEonxError,
      logEventLegacy,
      resetCart,
      eonxLoginWithPartner,
      venueSlug,
      replaceCleanPath,
    ],
  )

  useEffectOnce(() => {
    if (!resolved && query.eonxCheckInId && partner === Partner.Eonx) {
      logEventLegacy(`Eonx check in ID found`)
      void eonxCheckIn({
        eonxCheckInId: String(query.eonxCheckInId),
      })
    }
  })

  return {
    partnerResolved: resolved,
    partnerError: eonxLoginWithPartnerError,
  }
}
