import React from 'react'

import { ProfileNavBarIcon } from '@/components/GuestProfile/ProfileNavbarIcon'
import { useVenueContext } from '@/contexts/VenueOrderContext'

export const VenueHeaderInfo = ({
  venueName,
  venueId,
}: {
  venueName: string
  venueId: string
}) => {
  const { venueSlug } = useVenueContext()

  return (
    <div className="mb-1 flex w-full items-center justify-start gap-5">
      <h1 className="line-clamp-3 flex-grow my-heading-lg">{venueName}</h1>
      <ProfileNavBarIcon
        venueSlug={venueSlug}
        venueId={venueId}
        venueName={venueName}
        className="!mr-0 !h-9 !w-9 !rounded-full !bg-surface-bright !py-0 !text-foreground"
        isUserFilledIcon
      />
    </div>
  )
}
