import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { OrderingWindowsForm } from '@/components/Ordering/OrderingWindowsForm'
import { ResponsiveSheet } from '@/components/ResponsiveSheet'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { CartLandingQuery, OrderingType } from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'
import { CustomerStoreContext } from '@/stores/CustomerStore'

interface Props {
  open: boolean
  cart: CartLandingQuery['getCart']
  onOpenChange: () => void
}

export const OrderingWindowSheet = ({ open, cart, onOpenChange }: Props) => {
  const { logEventLegacy } = useLoggerLegacy()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const { showOrderingTypeModal } = useContext(CustomerStoreContext)
  useEffect(() => {
    showOrderingTypeModal()
  }, [showOrderingTypeModal])

  const handleOnSubmit = useCallback(() => {
    logEventLegacy('Updated ordering window on venue landing page')
    onOpenChange()
  }, [logEventLegacy, onOpenChange])

  let title = (
    <FormattedMessage defaultMessage="Select ordering window" id="namfZU" />
  )

  if (orderingType === OrderingType.Delivery) {
    title = (
      <FormattedMessage defaultMessage="Select delivery window" id="MxHwwY" />
    )
  } else if (orderingType === OrderingType.PickUp) {
    title = (
      <FormattedMessage defaultMessage="Select pickup window" id="17C4ef" />
    )
  }

  return (
    <ResponsiveSheet open={open} title={title} onOpenChange={onOpenChange}>
      <OrderingWindowsForm
        venueSlug={venueSlug}
        orderingType={orderingType}
        onClose={handleOnSubmit}
        startDate={cart?.orderingWindowStartDate}
      />
    </ResponsiveSheet>
  )
}
