import { TransformType } from '@mr-yum/cdn-image'
import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { BlockedFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { useIntl } from 'react-intl'

import { FlexibleWaitTimesBadge } from '@/components/FlexibleWaitTimes/FlexibleWaitTimesLabel'
import { MenuBadge } from '@/components/Menu/MenuBadge'
import { Image } from '@/components/Shared/Image'
import { VenueLandingMenuCategoryFragment } from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'
import { OrderingTypeSlug, routes } from '@/lib/routes'

import { DefaultImage } from './DefaultImage'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  unavailable?: boolean
  priorityImage?: boolean
  isTwoColumn?: boolean
  category: VenueLandingMenuCategoryFragment
}

export const MenuCategoryCardItem = ({
  venueSlug,
  orderingTypeSlug,
  unavailable,
  priorityImage = false,
  isTwoColumn = true,
  category,
}: Props) => {
  const { logEventLegacy } = useLoggerLegacy()
  const intl = useIntl()

  return (
    <RouteLink
      key={category.id}
      route={routes.menuCategory}
      pathData={{
        venueSlug,
        orderingTypeSlug,
        category: category.slug,
      }}
    >
      <a
        className="w-full bg-surface-ui-background text-foreground"
        onClick={() => {
          logEventLegacy('Clicked on category on venue landing page', {
            categoryFromDatabase: category,
          })
        }}
        aria-label={intl.formatMessage({
          defaultMessage: 'Menu category',
          id: '88OQkh',
        })}
      >
        <div className="background-image-category relative z-[1] mb-3 h-0 w-full overflow-hidden rounded bg-surface-subtle bg-cover p-4 pt-[37%]">
          {unavailable && (
            <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-black bg-opacity-60">
              <MenuBadge className="absolute left-2 top-2">
                <BlockedFilledIcon className="mr-1 h-4 w-4 " />
                Not available
              </MenuBadge>
            </div>
          )}

          {category.image && (
            <div className="absolute left-0 top-0 h-full w-full transition-transform duration-300 ease-in-out hover:scale-105">
              <Image
                image={category.image}
                transform={TransformType.VLP}
                alt={category.name}
                className="z-[-1]"
                layout="fill"
                objectFit="cover"
                sizes={
                  isTwoColumn ? '(max-width: 600px) 173px, 358px' : '358px'
                }
                priority={priorityImage}
              />
            </div>
          )}
          {!category.image && (
            <div className="absolute left-0 top-0 h-full w-full transition-transform duration-300 ease-in-out hover:scale-105">
              <DefaultImage />
            </div>
          )}
          {!unavailable && category.isWaitTimeActive && (
            <div className="absolute left-2 top-2">
              <FlexibleWaitTimesBadge
                estimationInMinutes={category.waitTimeEstimationInMinutes}
              />
            </div>
          )}
        </div>

        <h2 className="my-label-md hover:underline">{category.name}</h2>
      </a>
    </RouteLink>
  )
}
