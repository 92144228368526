import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CartValidator } from '@/components/Cart/validation'
import { OrderingWindow } from '@/components/Ordering/OrderingWindow'
import { OrderingType } from '@/gql/graphql'
import { useLoggerLegacy } from '@/hooks/useLogger'

import { useSheetToggle } from './utils/sheetUtils'

interface Props {
  cart?: CartValidator | null
  orderingType: OrderingType
  hasNoPaths?: boolean
  isAsap?: boolean
}

export const OrderingWindowButton = ({
  cart,
  orderingType,
  hasNoPaths,
  isAsap,
}: Props) => {
  const { logEventLegacy } = useLoggerLegacy()
  const sheetToggle = useSheetToggle()
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    logEventLegacy('Clicked on ordering window selector')
    void sheetToggle({
      sheet: 'order-window',
    })
  }, [logEventLegacy, sheetToggle])

  const startDate = cart?.orderingWindowStartDate
  const tableNumber = cart?.tableNumber

  if (hasNoPaths) {
    return null
  }

  if (orderingType !== OrderingType.Delivery && tableNumber) {
    return null
  }

  return (
    <Button
      type="button"
      variant="link-primary"
      size="sm"
      onClick={handleOnClick}
      className="min-h-5 gap-0"
      aria-label={intl.formatMessage({
        defaultMessage: 'Change ordering window',
        id: 'yJPkAI',
      })}
      data-testid="change-ordering-window"
      rightIcon={
        <ChevronDownIcon width={20} height={20} className="flex-shrink-0" />
      }
    >
      {startDate && (
        <OrderingWindow
          orderingType={orderingType}
          startDate={startDate}
          endDate={cart?.orderingWindowEndDate}
          className="text-left"
        />
      )}

      {!startDate &&
        intl.formatMessage({
          defaultMessage: 'Select ordering window',
          id: 'namfZU',
        })}

      {isAsap && (
        <div data-testid="asap-text">
          <FormattedMessage defaultMessage="ASAP" id="K5T4eC" />
        </div>
      )}
    </Button>
  )
}
